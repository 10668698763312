.component.component-image {
  figure {
    padding-bottom: 30px;
    img {

    }
    figcaption {
      font-style: italic;
    }
  }
}

.component.component-image.component-images {
  .col {
    padding-bottom: 30px;
  }
  figure {
    padding-bottom: 0;
    margin: 0;
    overflow: hidden;
    img {
      transform: scale(1);
      transition: all .5s;
    }
  }
  figure:hover {
    img {
      transform: scale(1.2);
    }
  }
}
