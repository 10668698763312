html {
  scroll-behavior: smooth;
}
body {
  color: $color-black;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  background-repeat: repeat;
  background-size: 65%;

  .siteWrapper {
    height: calc(100vh - #{$height-navigation});
  }
}
.lobster, .Lobster, .font-lobster {
  font-family: Lobster;
}
.market, .Market, .font-market {
  font-family: Market;
}
a {
  text-decoration: none;
  color: $color-black;
}
a:hover {
  text-decoration: underline;
  color: $color-ci;
}
a img {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.list-unstyled {
  list-style: none;
}
.btn-primary {
  color: $color-white;
  background-color: $color-ci;
  border-color: $color-ci;
}
.btn-primary:hover {
  color: $color-black;
  background-color: $color-ci-light;
  border-color: $color-ci-light;
}
label {
  font-weight: bold;
}
.bg-white {
  background: $color-white;
}
