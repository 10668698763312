@use "sass:color";

$color-ci: #3799a9;
$color-white: #fff;
$color-black: #000;
$color-ci-light: rgb(215, 232, 231);
$color-ci-secondary: hsla(44, 76%, 52%, 1);
$color-ci-secondary-light: hsl(44, 76%, 94%);

$height-navigation: 160px;

.color-ci {
  color: $color-ci;
}

.color-ci-light {
  color: $color-ci-light;
}

.bg-ci-secondary-light {
  background: $color-ci-secondary-light;
}
