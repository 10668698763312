.img-counter-1,
.img-counter-2,
.img-counter-3,
.img-counter-4,
.img-counter-5,
.img-counter-6,
.img-counter-7,
.img-counter-8,
.img-counter-9,
.img-counter-10 {
  div {
    height:100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.img-counter-2 { div { height:calc(100% / 2); } }
.img-counter-3 { div { height:calc(100% / 3); } }
.img-counter-4 { div { height:calc(100% / 4); } }
.img-counter-5 { div { height:calc(100% / 5); } }
.img-counter-6 { div { height:calc(100% / 6); } }
.img-counter-7 { div { height:calc(100% / 7); } }
.img-counter-8 { div { height:calc(100% / 8); } }
.img-counter-9 { div { height:calc(100% / 9); } }
.img-counter-10 { div { height:calc(100% / 10); } }

main {
  padding: 30px !important;
  background-color: $color-white;

  @media (min-width: 992px) {
    padding: 50px 100px !important;
  }

  @media (min-width: 1200px) {
    padding: 90px 180px !important;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  .anchors {
    padding-bottom: 50px;
    a {
      margin-right: 20px;
      margin-bottom: 10px;
      font-size: 15px;
      padding: 7px;
      background-color: $color-ci;
      color: $color-white;
      transition: all 0.3s ease-out;
    }
    a:hover {
      background-color: $color-ci-light;
      color: $color-black;
    }
  }
}

.component-breakout {
  margin-left: -30px;
  margin-right: -30px;

  @media (min-width: 992px) {
    margin-left: -50px;
    margin-right: -50px;
  }

  @media (min-width: 1200px) {
    margin-left: -90px;
    margin-right: -90px;
  }
}

.video-wrapper {
  background-color: $color-ci;
  position: relative;
  padding-bottom: 66%; /* 16:9 */
  height: 0;
  margin-bottom: 40px;

  iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    margin:30px;
  }
}

.component blockquote {
  background-color: #ededed;
  padding: 30px;
  margin-top: 10px;
}

.cta-wrapper {
  margin-bottom: 40px;

  .btn {
    color: $color-white;
    background-color: $color-ci;
    border-color: $color-ci;
    transition: all 0.3s ease;
  }
  .btn:hover {
    color: $color-black;
    background-color: $color-ci-light;
    border-color: $color-black;
  }
  .btn.no-bg,
  .btn.no-bg:hover {
    border-color: $color-black;
  }

}



.cta-wrapper.imageWrapper {

  overflow: hidden;

  .imageWrapperInner {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    transition: all .5s;
    transform: scale(1.0);
  }

  .col-bg-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

}

instagram.row {
  padding:10px;
  .col {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
}

.table {
  margin-bottom: 3rem;
  .tr {
    padding-bottom: 15px;
    .td {
      a {
        text-decoration: underline;
      }
    }
  }
}
[data-freeform-table] {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {

  .table {

    display: table;
    width: 100%;

    .tr:hover {
      background-color: $color-ci-light;
    }
    .tr {

      transition: all .3s;
      background-color: $color-white;
      display: table-row;
      padding-bottom: 0;

      .td {
        display: table-cell;
        padding:5px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 1px;
        border-bottom: 1px solid $color-ci-light;
      }

      /*
      .td.td-title {
        min-width: 400px;
      }
      .td.td-ort {
        min-width: 100px;
      }
      .td.td-jahr {
        min-width: 60px;
      }
      .td.td-datum {
        min-width: 60px;
      }
      */

    }

    .tr.th {
      display: table-header-group;
      .td {
        border-bottom: 1px solid $color-ci;
        font-weight: bold;
      }
    }

  }

}
