.theme--yellow__container {

  // .navigationWrapper {
  //   background-color: #ededed;
  // }

  // .navigationWrapper .active a {
  //   color: $color-ci-secondary;
  // }

  h1 {
    display: inline-flex;
    //padding-right: 5px;

    box-shadow: inset 0 -20px 0 white, inset 0 -32px 0 $color-ci-secondary;

    @media (min-width: 576px) {
      box-shadow: inset 0 -25px 0 white, inset 0 -40px 0 $color-ci-secondary;
    }

    @media (min-width: 992px) {
      padding-left: 50px;
      margin-left: -50px;
    }

   @media (min-width: 1200px) {
      padding-left: 90px;
      margin-left: -90px;
    }
  }

  h2 {
    color: $color-ci-secondary;
  }

  .video-wrapper {
    background-color: $color-ci-secondary;
  }

  .cta-wrapper:not(.bg-ci-secondary-light) h2 {
    color: #000;
  }

  // Buttons
  .cta-wrapper .bg-ci-secondary-light,
  .btn.btn-theme-yellow,
  .form-group .btn {
    @include btn-theme-yellow;
  }

  .table .tr.th .td {
    border-color: $color-ci-secondary !important;
  }

  .table .tr .td {
    border-color: $color-ci-secondary-light !important;
  }

  .table .tr:hover {
    background-color: $color-ci-secondary-light !important;
  }

  .table .tr:hover a {
    color: $color-black !important;
  }


  .form-control {
    border-color: $color-ci-secondary;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-ci-secondary, .4);
    }
  }

  .component blockquote {
    background-color: $color-ci-secondary-light;
  }

  [class^="form-wrapper-"] {
    background-color: $color-ci-secondary-light;
    margin-left: -30px;
    margin-right: -30px;
    padding: 3rem;

    @media (min-width: 992px) {
      margin-left: -50px;
      margin-right: -50px;
    }

    @media (min-width: 1200px) {
      margin-left: -90px;
      margin-right: -90px;
    }

    h2.h1 {
      color: $color-black;
    }
  }
}

.slide.theme--yellow {
  h2,
  p {
    color: $color-ci-secondary;
  }
  .btn {
      @include btn-theme-yellow--inverted;
  }
}

.theme--yellow__slider .slick-active button:before {
  color: $color-ci-secondary !important;
}

.theme--yellow__slider {
  background-color: $color-ci-secondary-light;
}
