@mixin btn-theme-yellow {
    background-color: $color-ci-secondary !important;
    border-color: $color-ci-secondary !important;

    &:hover {
      color: initial !important;
      background-color: lighten($color-ci-secondary, 25%) !important;
      border-color: $color-ci-secondary !important;
    }

    &:active {
      color: initial !important;
      background-color: $color-ci-secondary !important;
      border-color: $color-ci-secondary !important;
      box-shadow: 0 0 0 .2rem rgba($color-ci-secondary-light, .5) !important;
    }

    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 .2rem rgba($color-ci-secondary-light, .5) !important;
    }
}

@mixin btn-theme-yellow--inverted {
    color: #fff;
    font-weight: bold;
    background-color: lighten($color-ci-secondary, 20%) !important;
    border-color: lighten($color-ci-secondary, 20%) !important;

    &:hover {
      //color: initial !important;
      background-color: $color-ci-secondary !important;
      border-color: $color-ci-secondary !important;
    }

    &:active {
      //color: initial !important;
      background-color: $color-ci-secondary !important;
      border-color: $color-ci-secondary !important;
      box-shadow: 0 0 0 .2rem rgba($color-ci-secondary-light, .5) !important;
    }

    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 .2rem rgba($color-ci-secondary-light, .5) !important;
    }
}
