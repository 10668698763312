angebote {

  .card-header {
    background-color: $color-ci-light;
  }

  .btn {
    bottom: 0px;
    width: 100%;
    left: 0;
    border-top-left-radius:0;
    border-top-right-radius:0;
    position: absolute;
  }

  .card {
    box-shadow: none;
    transition: all 0.3s ease-out;
  }
  .card:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
}
