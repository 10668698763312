/*default version*/
@font-face {
    font-family: 'Lobster';
    src:    url('/fonts/Lobster_1.3.otf')
    format('opentype');
}
@font-face {
    font-family: 'Market';
    src:    url('/fonts/Market-Regular.otf')
    format('opentype');
}

.h1, h1 {
    font-size: 2rem;
    font-family: 'Lobster';
    padding-bottom: 15px;

    @media (min-width: 576px) {
      font-size: 2.8rem;
    }
}