.navbar-social-media {

  z-index: 1040;

  .border-left-black {
    border-left: 1px solid $color-black;
    font-size: 14px;
  }
  .nav-link-icon {
    line-height: 0;
  }

}
.navigationWrapper {
  background-color: #ededed;
  height: $height-navigation;
  .active a {
    color: $color-ci;
  }
  .logo {
    transition: all 0.3s ease-out;
    transform: rotate(0deg);
    margin-top: 10px;
    height: calc(#{$height-navigation} - 20px);
    @media (min-width: 1200px) {
      height: auto;
      margin-top: 15px;
      width: calc(#{$height-navigation} * 1800/1050);
    }
  }
  .logo:hover {
    transform: rotate(-3deg);
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  nav {
    margin-left: 10px;
    margin-right: 10px;
    @media (min-width: 1200px) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  button.navbar-toggler {
    border:2px solid $color-ci;
    span {
      color: $color-ci;
    }
  }
}
.mainnav {
  .nav-link {
    transition: all .15s ease-in-out;
    font-size: 17px;
    border-radius: .25rem;
  }
  .nav-link:hover {
    background-color: $color-ci;
    color: $color-white !important;
  }
}

@media (max-width: 1199px) {
  #navbarsDefault {
    background: $color-ci-light;
    width: 100%;
    margin-left: -10px;
    margin-right: -10px;
    padding: 20px;
  }
}

.navigationWrapper {

  button.navbar-toggler {
    border: 1px solid $color-ci;
    background-color: $color-white;
    transition: all 0.1s ease-out;

    .navline {
      height: 0;
      border-top: 1px solid $color-ci;
      border-bottom: 1px solid $color-ci;
      width: 30px;
      transition: all 0.1s ease-out;
      left: 0px;
      top: 14px;
    }
    .navline-1 {
      transform: rotate(45deg);
    }
    .navline-2 {
      transform: rotate(135deg);
    }
    .navline-3 {
      transform: rotate(135deg);
    }
  }

  .navbar-toggler.collapsed {
    .navline {
      left: 0px;
      transform: rotate(0deg);
    }
    .navline-1 {
      top: 4px;
    }
    .navline-2 {
      top: 14px;
    }
    .navline-3 {
      top: 24px;
    }
  }

}
.navigationWrapper button.navbar-toggler.collapsed:hover {
  .navbar-toggler-icon .navline-1 {
    left: -5px;
  }
  .navbar-toggler-icon .navline-3 {
    left: 5px;
  }
}
