.body-sectionHomepage {

  .imageWrapper {
    height: calc(50vh - (#{$height-navigation} / 2));
    overflow: hidden;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: rgba($color-ci, .35);
    }

    .imageWrapperInner {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 100%;
      transition: all .5s;
    }

    .col-bg-image {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .title-brush-stroke {
      font-size: 36px;
      line-height: 1;
      background-image: url("/images/paint-brush-stroke.svg");
      background-repeat: no-repeat;
      background-position: left 50%;
      background-size: cover;
      margin: auto;
      padding: 20px 90px 10px 30px;
      position: absolute;
      z-index: 2;
      bottom: 20%;
    }
  }

  a.imageWrapper {
    &:hover {
      color: #000;
    }

    &:before {
      transition: all 0.7s ease;
    }

    &:hover:before {
      opacity: 0;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 150' preserveAspectRatio='none' style='height: 100%25; width: 100%25;'%3E%3Cpath d='M-2.82,70.55 C268.06,100.16 389.39,92.27 500.56,93.25 L500.00,150.00 L0.00,150.00 Z' style='stroke: none; fill: %233799a9;'%3E%3C/path%3E%3C/svg%3E");
      transition: transform 0.4s ease-in-out;
      transform: translateY(0);
      opacity: 0.35;
    }

    &:hover:after {
      transform: translateY(-100%);
    }
  }

  .sliderWrapper {
    height: calc(50vh - (#{$height-navigation} / 2));
  }

  .slider-home {
    height: 100%;
    padding: 30px;

    h2 {
      font-size: 28px;
      @media (min-width: 600px) {
        font-size: 48px;
      }
      @media (min-width: 1200px) {
        font-size: 60px;
      }
      font-weight: bold;
      padding-bottom: 10px;
    }
    h2.headlinelong {
      @media (min-width: 600px) {
        font-size: 44px;
      }
    }
    p {
      font-size: 24px;
      @media (min-width: 600px) {
        font-size: 36px;
      }
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (min-width: 1200px) {
        font-size: 36px;
      }
      line-height: 1;
    }
    p.textlong {
      @media (min-width: 1200px) {
        font-size: 32px;
      }
    }
    .slick-dots {
      left:0;
      position: absolute;
      bottom: 20px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      li button:before {
        font-size: 16px;
      }
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      font-size: 18px;
      color: $color-ci;
    }

  }

}
